/* eslint-disable */
import "./assets/main.css";

import { createApp } from "vue";

import PrimeVue from 'primevue/config';
import App from "./App.vue";
import router from "./router";

import "primeicons/primeicons.css"; //icons
import "primevue/resources/primevue.min.css"; //core css
import "primevue/resources/themes/lara-light-purple/theme.css"; //theme
// import VueToast from "vue-toastification";
// import "vue-toastification/dist/index.css";

//prime flex
import "primeflex/primeflex.css";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import Image from "primevue/image";
import Password from "primevue/password";
import Button from "primevue/button";

const app = createApp(App);

// app.use(VueToast);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
app.component("InputText", InputText);
app.component("InputMask", InputMask);
app.component("InputNumber", InputNumber);
app.component("Image", Image);
app.component("Password", Password);
app.component("Button", Button);

app.mount("#app");
